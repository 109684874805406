@import './light-stepped-colors';
@import './dark-stepped-colors';

:root,
.ion-palette-dark.ios,
.ion-palette-dark.md {
  --ion-color-facebook: #0766FF;
  --ion-color-facebook-rgb: 7,102,255;
  --ion-color-facebook-contrast: #ffffff;
  --ion-color-facebook-contrast-rgb: 255,255,255;
  --ion-color-facebook-shade: #065ae0;
  --ion-color-facebook-tint: #2075ff;
}

.ion-color-facebook {
  --ion-color-base: var(--ion-color-facebook);
  --ion-color-base-rgb: var(--ion-color-facebook-rgb);
  --ion-color-contrast: var(--ion-color-facebook-contrast);
  --ion-color-contrast-rgb: var(--ion-color-facebook-contrast-rgb);
  --ion-color-shade: var(--ion-color-facebook-shade);
  --ion-color-tint: var(--ion-color-facebook-tint);
}