.ion-palette-dark.ios,
.ion-palette-dark.md {
  --ion-background-color: #252525;
  --ion-background-color-rgb: 37,37,37;

  --ion-text-color: #EAEAEA;
  --ion-text-color-rgb: 234,234,234;

  --ion-text-color-step-50: #e0e0e0;
  --ion-text-color-step-100: #d6d6d6;
  --ion-text-color-step-150: #cccccc;
  --ion-text-color-step-200: #c3c3c3;
  --ion-text-color-step-250: #b9b9b9;
  --ion-text-color-step-300: #afafaf;
  --ion-text-color-step-350: #a5a5a5;
  --ion-text-color-step-400: #9b9b9b;
  --ion-text-color-step-450: #919191;
  --ion-text-color-step-500: #888888;
  --ion-text-color-step-550: #7e7e7e;
  --ion-text-color-step-600: #747474;
  --ion-text-color-step-650: #6a6a6a;
  --ion-text-color-step-700: #606060;
  --ion-text-color-step-750: #565656;
  --ion-text-color-step-800: #4c4c4c;
  --ion-text-color-step-850: #434343;
  --ion-text-color-step-900: #393939;
  --ion-text-color-step-950: #2f2f2f;

  --ion-background-color-step-50: #2f2f2f;
  --ion-background-color-step-100: #393939;
  --ion-background-color-step-150: #434343;
  --ion-background-color-step-200: #4c4c4c;
  --ion-background-color-step-250: #565656;
  --ion-background-color-step-300: #606060;
  --ion-background-color-step-350: #6a6a6a;
  --ion-background-color-step-400: #747474;
  --ion-background-color-step-450: #7e7e7e;
  --ion-background-color-step-500: #888888;
  --ion-background-color-step-550: #919191;
  --ion-background-color-step-600: #9b9b9b;
  --ion-background-color-step-650: #a5a5a5;
  --ion-background-color-step-700: #afafaf;
  --ion-background-color-step-750: #b9b9b9;
  --ion-background-color-step-800: #c3c3c3;
  --ion-background-color-step-850: #cccccc;
  --ion-background-color-step-900: #d6d6d6;
  --ion-background-color-step-950: #e0e0e0;

    --ion-item-background: var(--ion-background-color);
    --ion-border-color: var(--ion-background-color-step-150); // Border color
    --ion-toolbar-background: var(--ion-background-color-step-150); // Background of the Toolbar
    --ion-item-border-color: var(--ion-background-color-step-150); // Border color of the Item
}