@import "../../../common/style/comon-themes";

:root,
.ion-palette-dark.ios,
.ion-palette-dark.md {
  /** primary **/
  --ion-color-primary: #008080;
  --ion-color-primary-rgb: 0,128,128;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #007171;
  --ion-color-primary-tint: #1a8d8d;
}
