/* (Optional) This is added to prevent the flashing that happens when toggling between palettes */
ion-item {
  --transition: none;
}

* {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

ion-menu {
  .align-center {
    text-align: center;
    border: none;

    svg {
      width: 60%;
      height: 60%;
    }
  }
}

.insigne-modal-full {
  --min-height: 80vh;
}

ion-content {
  --padding-bottom: 80px;
}

ion-thumbnail {
  img {
    border-radius: 2px;
  }
}

swiper-container {
  --swiper-preloader-color: var(--ion-color-primary);
  --swiper-pagination-color: var(--ion-color-primary);
  --swiper-pagination-bullet-inactive-color: var(--ion-color-primary);
}

mark {
  background-color: var(--ion-color-primary);
  color: black;
}

cmn-instant-search-highlight > span > em, mark {
  background-color: unset;
  color: var(--ion-color-primary);
  font-style: normal;
  font-weight: bold;
}

ion-modal.pins-index-picture-modal {
  @media only screen and (min-height: 768px) and (min-width: 768px) {
    --height: 95vh;
    --width: 90vw;
  }
}
