:root {
  --ion-background-color: #FBFBFB;
  --ion-background-color-rgb: 251,251,251;

  --ion-text-color: #1e1e1e;
  --ion-text-color-rgb: 30,30,30;

  --ion-text-color-step-50: #292929;
  --ion-text-color-step-100: #343434;
  --ion-text-color-step-150: #3f3f3f;
  --ion-text-color-step-200: #4a4a4a;
  --ion-text-color-step-250: #555555;
  --ion-text-color-step-300: #606060;
  --ion-text-color-step-350: #6b6b6b;
  --ion-text-color-step-400: #767676;
  --ion-text-color-step-450: #818181;
  --ion-text-color-step-500: #8d8d8d;
  --ion-text-color-step-550: #989898;
  --ion-text-color-step-600: #a3a3a3;
  --ion-text-color-step-650: #aeaeae;
  --ion-text-color-step-700: #b9b9b9;
  --ion-text-color-step-750: #c4c4c4;
  --ion-text-color-step-800: #cfcfcf;
  --ion-text-color-step-850: #dadada;
  --ion-text-color-step-900: #e5e5e5;
  --ion-text-color-step-950: #f0f0f0;

  --ion-background-color-step-50: #f0f0f0;
  --ion-background-color-step-100: #e5e5e5;
  --ion-background-color-step-150: #dadada;
  --ion-background-color-step-200: #cfcfcf;
  --ion-background-color-step-250: #c4c4c4;
  --ion-background-color-step-300: #b9b9b9;
  --ion-background-color-step-350: #aeaeae;
  --ion-background-color-step-400: #a3a3a3;
  --ion-background-color-step-450: #989898;
  --ion-background-color-step-500: #8d8d8d;
  --ion-background-color-step-550: #818181;
  --ion-background-color-step-600: #767676;
  --ion-background-color-step-650: #6b6b6b;
  --ion-background-color-step-700: #606060;
  --ion-background-color-step-750: #555555;
  --ion-background-color-step-800: #4a4a4a;
  --ion-background-color-step-850: #3f3f3f;
  --ion-background-color-step-900: #343434;
  --ion-background-color-step-950: #292929;
}